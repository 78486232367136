import React, { useState } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"

const FormAddTestimonial = () => {
  const [title, setTitle] = useState("")
  const [desc, setDesc] = useState("")
  const [date, setDate] = useState("")
  const [path, setPath] = useState("")
  const [file, setFile] = useState("") //
  const [msg, setMsg] = useState("")
  const navigate = useNavigate()

  const loadPdf = (e) => {
    //
    const pdf = e.target.files[0]
    setFile(pdf)
    setPath(pdf ? pdf.name : "")
  }

  const saveTestimonial = async (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append("file", file)
    formData.append("title", title)
    formData.append("desc", desc)
    formData.append("date", date)
    formData.append("path", path)
    try {
      await axios.post("https://main.mzac.org/testimonials", formData, {
        // desc: desc,
        // date: date,
        // path: path,
        headers: {
          "Content-type": "multipart/form-data",
        },
      })
      navigate("/admin/testimonials")
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg)
      }
    }
  }

  return (
    <div>
      <h1 className="title">Testimonial</h1>
      <h2 className="subtitle">Add New Testimonial</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={saveTestimonial}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Title</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Testimonial Title"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Description</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                    placeholder="Testimonial Description"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Created Date</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    placeholder="Date Format: year-month-day"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">File Name</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={path}
                    onChange={(e) => setPath(e.target.value)}
                    placeholder="File name must be same as Image file"
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Image File</label>
                <div className="control">
                  <div className="file">
                    <label className="file-label">
                      <input
                        type="file"
                        className="file-input"
                        onChange={loadPdf}
                      />
                      <span className="file-cta">
                        <span className="file-label">
                          {" "}
                          {path ? path : "Choose a file..."}
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormAddTestimonial
