import React from "react";
import "./childrenministry.css";
import kids from "../../../Images/kids.png";
import parents from "../../../Images/parents.png";

import { useTranslation } from "react-i18next";

import enTranslation from "../../../locales/en.json";
import chTranslation from "../../../locales/ch.json";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ch: { translation: chTranslation },
  },
  lng: "ch",
  fallbacklng: "ch",
  interpolation: {
    escapeValue: false,
  },
});

function Childrenministry() {
  const { t } = useTranslation();

  return (
    <div className="history-container">
      <a
        href="/Children/kids"
        rel="noopener noreferrer"
        className="history-link"
      >
        <div className="history-image">
          <img src={kids} alt="Kid resources" />
        </div>
        <div className="history-context">
          <h3>{t("childrenMinistry.forKids")}</h3>
        </div>
      </a>
      <a
        href="/Children/parents"
        rel="noopener noreferrer"
        className="history-link"
      >
        <div className="history-image">
          <img src={parents} alt="Parent resources" />
        </div>
        <div className="history-context">
          <h3>{t("childrenMinistry.forParents")}</h3>
        </div>
      </a>
    </div>
  );
}

export default Childrenministry;
