import React, { useState, useEffect } from "react"
import axios from "axios"
import "./Home.css"
import bible from "../../Images/bible.jpg"
import verse from "../../Images/verse.png"
import xianlogo from "../../Images/xianlogo.png"
import children from "../../Images/children.png"
import socialconcern from "../../Images/socialconcern.jpg"
import alphac from "../../Images/alphac.png"
import { Link } from "react-router-dom"

import { useTranslation } from "react-i18next"

import enTranslation from "../../locales/en.json"
import chTranslation from "../../locales/ch.json"

import i18n from "i18next"
import { initReactI18next } from "react-i18next"
//import arrowLeft from "../../Images/arrow-left.png"
//import arrowRight from "../../Images/arrow-right.png"

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ch: { translation: chTranslation },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
})

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ch: { translation: chTranslation },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
})

const Home = () => {
  const [events, setEvents] = useState([])
  const [displayedEvents, setDisplayedEvents] = useState([])
  const [displayedEvents2, setDisplayedEvents2] = useState([])
  const [showMore, setShowMore] = useState(false)
  const { t } = useTranslation()
  const [verseText, setVerseText] = useState("")
  const [startIndex, setStartIndex] = useState(0)
  useEffect(() => {
    // Fetch verse text from your backend
    axios
      .get("https://main.mzac.org/verses")
      .then((response) => {
        // Concatenate the verses into a single string
        // const verseText = response.data.map(verse => verse.desc).join(' ');
        // setVerseText(verseText);
        const verses = response.data

        // Get the last verse
        const lastVerse =
          verses.length > 0 ? verses[verses.length - 1].desc : ""

        // Set the last verse text
        setVerseText(lastVerse)
      })
      .catch((error) => {
        console.error("Error fetching verse text:", error)
      })

    //   axios.get('https://main.mzac.org/events')
    //     .then(response => {
    //       setEvents(response.data);
    //       setDisplayedEvents(response.data.slice(-3).reverse());
    //     })
    //     .catch(error => {
    //       console.error('Error fetching data:', error);
    //     });
    // }, []);

    // const handleShowMore = () => {

    //   const last6Events = events.slice(-6).reverse();
    //   setDisplayedEvents(last6Events);
    // };
    axios
      .get("https://main.mzac.org/events")
      .then((response) => {
        //  setEvents(response.data);
        //  setDisplayedEvents(response.data.slice(startIndex, startIndex + 3).reverse());
        const reversedEvents = response.data.slice() // Reverse the order of events
        setEvents(reversedEvents)
        setStartIndex(
          reversedEvents.length >= 3 ? reversedEvents.length - 3 : 0
        )
      })
      .catch((error) => {
        console.error("Error fetching data:", error)
      })
  }, [])
  useEffect(() => {
    setDisplayedEvents(events.slice(startIndex, startIndex + 3).reverse())
  }, [startIndex, events])
  useEffect(() => {
    setDisplayedEvents2(events.slice(startIndex, startIndex + 6).reverse())
  }, [startIndex, events])

  // const handleShowMore = () => {
  //   //const maxStartIndex = Math.max(events.length - 6, 0);
  //   const maxStartIndex = events.length-6 ;
  //   setStartIndex(maxStartIndex);
  //   setDisplayedEvents2(events.slice(maxStartIndex, maxStartIndex + 6).reverse());
  // };
  const handleShowMore = () => {
    const last6Events = events.slice(-6).reverse()
    setDisplayedEvents(last6Events)
  }

  const handlePrevEvents = () => {
    const newIndex = Math.max(0, startIndex - 3)
    setStartIndex(newIndex)
  }

  const handleNextEvents = () => {
    const newIndex = Math.min(events.length - 3, startIndex + 3)
    setStartIndex(newIndex)
  }

  return (
    <div>
      <div className="hero2-image">
        <img className="bible" src={bible} alt="Bible" />
        <div className="verse-container">
          <img src={verse} alt="Verse of the Week" className="verse-image" />
          <div className="verse-text">{verseText}</div>
        </div>
        <img className="bible" src={bible} alt="Bible" />
      </div>
      <div className="welcome">
        <img className="welcome-image" src={xianlogo} alt="Xian Logo" />
        <h3>{t("home.welcome")}</h3>
        <p>{t("home.description")}</p>
        <hr className="hrLines" />
      </div>
      <div className="card-container">
        <a href="/Children" rel="noopener noreferrer" className="card">
          <img src={children} alt="Children Ministry" />
          <h4>{t("home.children")}</h4>
        </a>
        <a href="/Socialconcern" rel="noopener noreferrer" className="card">
          <img src={socialconcern} alt="Social Concern" />
          <h4>{t("home.socialConcern")}</h4>
        </a>
        <a href="/Alpha" rel="noopener noreferrer" className="card">
          <img src={alphac} alt="Alpha Courses" />
          <h4>{t("home.alpha")}</h4>
        </a>
      </div>

      <div className="upcoming">
        <h4>{t("home.upcoming")}</h4>
        <hr className="hrLines" />
      </div>

      <div className="show-more-container">
        {startIndex + 3 < events.length && (
          <div className="arrow-btn left-arrow" onClick={handleNextEvents}>
            {"<"}
          </div>
        )}
        <div className="card-container">
          {displayedEvents.map((event) => (
            <div className="card" key={event.uuid}>
              {/* <h2>{event.title}</h2>
          <p>Description: {event.desc}</p>
          <p>Date: {event.date}</p> */}
              <Link to={`/event/${event.uuid}`}>
                <img
                  src={`https://main.mzac.org/eventFile/${event.path}`}
                  alt="Event"
                  style={{
                    width: "300px",
                    height: "auto",
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                />
              </Link>
              <h4>{event.title}</h4>
              <h5>{event.date}</h5>
              <hr />
            </div>
          ))}
        </div>

        {/* Left and Right Arrows */}

        {startIndex > 0 && (
          <div className="arrow-btn right-arrow" onClick={handlePrevEvents}>
            {">"}
          </div>
        )}
      </div>
      {events.length > 3 && displayedEvents.length < 6 && (
        <div className="show-more-btn" onClick={handleShowMore}>
          {t("home.showMore")}
        </div>
      )}

    </div>

  )
}

export default Home
