import React from 'react'
import './testimonial1.css';
import testimonial1s from '../../../Images/testimonial1s.jpg'

function Testimonial1() {
  return (
    <div className='testimonial1-container'>
      <p className='testimonial-title'>Testimonial</p>
      <h2>Our Stories</h2>
      <div className="testimonial-context">
        <h4>The most beautiful story is that of God's son, Jesus Christ, who walks with us through the seasons of spring, summer, autumn, and winter.</h4>
      </div>
      <div className="testimonial1-image">
        <img src={testimonial1s} alt='Testimonial1 Small'/>
        <img src={testimonial1s} alt='Testimonial1 Small'/>
        <img src={testimonial1s} alt='Testimonial1 Small'/>
      </div>
      <div className="testimonial1-context">
        
        <p>大家好，我叫小绮，<br></br><br></br>
在信主前，我们这群“80后”，又被叫做垮掉的一代，我们缺乏甚至没有信仰，没有奉献的精神，一切以自我为中心，其实就是自私，这大概是所有我们这一代独生子女与生俱来的。我们从未想过要改变，以为这并不会影响他人、影响生活。但实际上确实很大影响了我成家之后的夫妻之间的生活，也许甚至在自己无意识下还会传染给下一代。
<br></br><br></br>

至于神观，究竟是否有神，我不能确定，我不是一个完全的无神论者，但也无法承认自己是个有神论者。因为我从小在教科书上学的宇宙大爆炸理论创造了宇宙，达尔文进化论表示人是从猿猴进化来的，地球上的植物和动物也是经过上亿年从微生物进化来的。
我大部分都只相信科学，其他诸如许多中国古代神话，女娲造人，盘古开天辟地，后羿射日等等或者国外的神话。神创造天地创造人，亚当夏娃伊甸园的传说，诺亚方舟大洪水的传说等等，我都只是当做故事来听，我从未当真，也没有思考过。但奇怪的是，有时候当我希望借助外力达成心愿或希望他人能够健康顺利，就会在心里对神，菩萨，老天，上帝，等等默念一些祈祷的话语，希望他们能听到我的声音，我可以得到帮助。就算我是有神论者，我肯定没有确定究竟那一位是真神。
<br></br><br></br>
我是2017年来到加拿大，我从2018年开始接触基督教，因为我在加拿大交到的第一个朋友她是基督徒，她也跟我一样在一个微信妈妈群里面，她总是乐于帮助其他人，总是很有耐心的回答别人的问题。由于我们新人来到魁北克学习和生活对学法语的困惑和难处，她老公是法籍华裔，法语是母语，于是他们夫妻在恩典堂的一间小教室组织周末学习法语小组教大家法语基础知识和练口语，所以我第一次接触了教会。
<br></br><br></br>
后来她又介绍我去学习英文的圣经课程并参加他们每周的小组聚会，我认识了一群信仰基督教的年轻本地朋友，他们是如此的充满爱心，总是拥有积极向上、奉献与感恩的态度，我见到的每个人都有着发自内心的喜乐与热情，我很信任也愿意与他们多接触，他们所拥有的正是我自身所缺失的，我想要向他们学习，我也想要变得更加善良，更加有爱心，更多的帮助他人！
<br></br><br></br>
这期间，在我儿子出生时发生的一件事让我一直无法忘记。我儿子刚出生发现感染金黄色葡萄球菌而被转到儿童医院ICU，我们当时医保卡还没办下来，新生儿也没有保险覆盖，也不知道要付多少医药费住院费。教会的朋友听说了这个坏消息后，我去的那一个小组的朋友组织了捐款，一定要给我转帐过来，竟然有三千多加币。我特别震惊，这么大一笔钱，一群刚认识不久还不太熟的朋友就能做到这样，我心里想哪怕是我的亲戚或者很好的闺蜜估计都没法像他们一样。后来我儿子住院一周多后出院了，账单显示七万多加币的天价，但万幸的是，我们医保卡批准时间是在出生时间之前，所以账单取消了！
<br></br><br></br>
现在我想这一定是大家帮我们不断的祷告换来的！感谢朋友们感谢主！同时，我相信我的那个带领我认识神的朋友她给我的影响也很大，在我每次家里遇到挫折，伤心欲绝看不到任何希望的时候，她总是鼓励我，总是往好的方向引导我，并给我讲很多她的神迹，她说很多事情靠自己没法解决，我们要信靠神，要靠圣灵在我们身体和心里做工，我们要靠不断的祷告，你肯定会越来越好！
<br></br><br></br>
后来由于2020年爆发疫情，加上我要在家里照顾孩子，我中断了去教会两年。感谢主没有放弃我，仍在我的门外不断叩门！今年6月份机缘巧合在朋友春梅的邀请下来到了锡安堂，孩子们很喜欢来这里和一群哥哥姐姐一起玩。我听到大家一起唱赞美诗，这些歌词如此打动人心，常常让人想流泪，就像被上帝的爱触摸。我也喜欢锡安堂教会的氛围，喜欢这里的兄弟姊妹，也喜欢主日祟拜时传道的证道，总是让人受到启发，感觉每一次都像充电一样。平时把电用完，或者积累一些负面情绪，周日就被充满电和心灵清洁。以上这一切都坚定了我成为一个基督徒的信心和决心。“众里寻她千百度, 暮然回首，那人却在灯火阑珊处。” 是的，耶稣基督应该就是我一直寻寻觅觅的真神。  
<br></br><br></br>
在这期间我学习完了启发课程和受洗课程。通过这些课程和传道每周日的讲道，我更加认识到神的伟大和恩典；我也认识到人生来就有罪，而且也都犯了罪行，但上帝因爱人早就为人准备了救赎，即通过他的独生子耶稣基督受死十字架，并用他的宝血来赎我们的罪。每每想到这些，我都无比感动想哭，因为我的某些罪我觉得我自己都无法原谅自己，我的某些错误我的父母或者另一半都对我没有耐心或者宽容，但这一切上帝都会无条件的给我，给我一次次的机会让我学习和成长。
<br></br><br></br>
感谢我的朋友们，带我来到教会，来到神的面前，让我能够有机会认识神。感谢主拣选了我，我愿意成为主的儿女，成为一个新造的人，一心依靠你。感谢主为我而死，使我可以被赦免，得着自由，我愿意打开心门，接受耶稣作我的救主和生命的主，求你管理我的一生，使我成为你所喜悦的人！愿所有的赞美与荣耀，都归于主！
<br></br>
2020-04-15
</p>
      </div>
    </div>
  )
}
export default Testimonial1