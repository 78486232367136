import React from 'react';
import './parents.css';
import { Link } from 'react-router-dom';
import biblecrafts from '../../../../Images/biblecrafts.png';
import guidetoworship from '../../../../Images/guidetoworship.png';
import pawstales from '../../../../Images/pawstales.png';
import biblecraftspdf from '../../../../PDF/big-picture-bible-crafts-pdf.pdf'; 
import guidetoworshippdf from '../../../../PDF/SimpleGuideToFamilyWorship.pdf'; 
import back from '../../../../Images/BACK.png';

function Parents() {
  return (
    <div className='parent-container'>
      <div className="parent-context">
        <h3>For Parents</h3>
      </div>
      <div className="parent-image">
        <a href={biblecraftspdf} target="_blank" rel="noopener noreferrer">
          <img src={biblecrafts} alt='Bible Crafts' />
        </a>
        <a href={guidetoworshippdf} target="_blank" rel="noopener noreferrer">
          <img src={guidetoworship} alt='A Simple Guide to Family Worship' />
        </a>
        <Link to="https://pawsandtales.org/listen"> 
          <img src={pawstales} alt='Paws Tales' />
        </Link>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <Link to="/Children"> 
          <img src={back} alt='BACK' />
      </Link>
    </div>
  );
}

export default Parents;


