import React from "react";
import { Link } from "react-router-dom";
import "./socialconcern2.css";
import socialconcern2s from "../../../../Images/Socialconcern2s.jpg";

import { useTranslation } from "react-i18next";

import enTranslation from "../../../../locales/en.json";
import chTranslation from "../../../../locales/ch.json";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ch: { translation: chTranslation },
  },
  lng: "ch",
  interpolation: {
    escapeValue: false,
  },
});

function Testimonial1() {
  const { t } = useTranslation();

  return (
    <div className="testimonial1-container">
      <p className="testimonial1-title">{t("socialConcern.socialconcern")}</p>
      <h3>{t("socialConcern.ourActivities")}</h3>
      <div className="testimonial1-context">
        <h4>{t("socialConcern.verse")}</h4>
      </div>
      <div className="testimonial1-image">
        <img src={socialconcern2s} alt="Social Concern 1 Small" />
        <img src={socialconcern2s} alt="Social Concern 1 Small" />
        <img src={socialconcern2s} alt="Social Concern 1 Small" />
      </div>
      <div className="testimonial1-context">
        <p>{t("socialConcern.concern12")}</p>
      </div>
      <Link to="/Socialconcern">
        <button className="btn btn-back">{t("testimonial.back")}</button>
      </Link>
    </div>
  );
}
export default Testimonial1;
