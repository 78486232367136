import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  user: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
}

export const LoginUser = createAsyncThunk(
  "admin/user/LoginUser",
  async (user, thunkAPI) => {
    try {
      const response = await axios.post("https://main.mzac.org/login", {
        email: user.email,
        password: user.password,
      })
      return response.data
    } catch (error) {
      if (error.response) {
        const message = error.response.data.msg
        return thunkAPI.rejectWithValue(message)
      }
    }
  }
)

export const getMe = createAsyncThunk(
  "admin/user/getMe",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get("https://main.mzac.org/me")
      return response.data
    } catch (error) {
      if (error.response) {
        const message = error.response.data.msg
        return thunkAPI.rejectWithValue(message)
      }
    }
  }
)

export const LogOut = createAsyncThunk("admin/user/LogOut", async () => {
  await axios.delete("https://main.mzac.org/logout")
})

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(LoginUser.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(LoginUser.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.user = action.payload
    })
    builder.addCase(LoginUser.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })

    // Get User Login
    builder.addCase(getMe.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getMe.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.user = action.payload
    })
    builder.addCase(getMe.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
  },
})

export const { reset } = authSlice.actions
export default authSlice.reducer
