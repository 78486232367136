import React from "react";
import "./videos.css";

import { useTranslation } from "react-i18next";

import enTranslation from "../../locales/en.json";
import chTranslation from "../../locales/ch.json";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ch: { translation: chTranslation },
  },
  lng: "ch",
  interpolation: {
    escapeValue: false,
  },
});

const Videos = () => {
  const { t } = useTranslation();

  return <div className="video-container">{t("video.underConstruction")}</div>;
};

export default Videos;
