import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { createRoot } from 'react-dom/client';
import App from './APP';
import { Provider } from 'react-redux';
import { store } from './store/store';
import axios from "axios";
import "bulma/css/bulma.css";
axios.defaults.withCredentials = true;

//const root = ReactDOM.createRoot(document.getElementById('root'));
const root =createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
    <App />
    </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

