import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import axios from "axios"

const VerseList = () => {
  const [verses, setVerses] = useState([])
  const [msg, setMsg] = useState("")

  useEffect(() => {
    getVerses()
  }, [])

  const getVerses = async () => {
    const response = await axios.get("https://main.mzac.org/verses")
    setVerses(response.data)
  }

  const deleteVerse = async (verseId) => {
    try {
      await axios.delete(`https://main.mzac.org/verses/${verseId}`)
      getVerses()
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg)
      }
    }
  }

  return (
    <div>
      <h1 className="title">Verses</h1>
      <h2 className="subtitle">List of Verses</h2>
      <Link to="/admin/verses/add" className="button is-primary mb-2">
        Add New
      </Link>
      <p className="has-text-centered">{msg}</p>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Verse Description</th>
            <th>Date</th>
            <th>Created By</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {verses.map((verse, index) => (
            <tr key={verse.uuid}>
              <td>{index + 1}</td>
              <td>{verse.desc}</td>
              <td>{verse.date}</td>
              <td>{verse.user.name}</td>
              <td>
                <Link
                  to={`/admin/verses/edit/${verse.uuid}`}
                  className="button is-small is-info"
                >
                  Edit
                </Link>
                <button
                  onClick={() => deleteVerse(verse.uuid)}
                  className="button is-small is-danger"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default VerseList
