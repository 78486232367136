import React, { useState, useEffect } from "react"
import axios from "axios"
import { useParams } from "react-router-dom"
import "./testimonial1.css"

function EventPage () {
  const { id } = useParams() // Get the testimonial ID from the URL
  const [event, setEvent] = useState(null)

  useEffect(() => {
    // Fetch testimonial data from the backend based on the ID
    axios
      .get(`https://main.mzac.org/events/${id}`)
      .then((response) => {
        setEvent(response.data)
      })
      .catch((error) => {
        console.error("Error fetching testimonial:", error)
      })
  }, [id]) // Include id in the dependency array to re-fetch data when id changes

  if (!event) {
    return <div>Loading event...</div>
  }

  const { title, desc, path, user, date } = event

  return (
    <div className="testimonial1-container">
      <p className="testimonial-title">Events</p>
      <h3>{title}</h3>
      <div className="testimonial1-image">
        <img
          src={`https://main.mzac.org/eventFile/${path}`}
          alt={`Event1 Small - ${title}`}
          style={{ width: "700px", height: "400px" }}
        />
      </div>
      <div className="testimonial1-context">
        {desc.split("_").map((item, index) => (
          <p key={index}>{item.trim()}</p>
        ))}
      </div>
    </div>
  )
}

export default EventPage
