import React, { useState, useEffect } from "react"
//import axios from "axios";
//import ReactPaginate from "react-paginate";
//import "bulma/css/bulma.css";
import AudioPlayer from "react-h5-audio-player"
import "react-h5-audio-player/lib/styles.css"
import audio from "../../Images/audio.png"

import xianlogo from "../../Images/xianlogo.png"

import { useTranslation } from "react-i18next"

import enTranslation from "../../locales/en.json"
import chTranslation from "../../locales/ch.json"

import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import "./sermons.css"
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ch: { translation: chTranslation },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
})

const AudioPage = () => {
  const { t } = useTranslation()
  const [originalListItems, setOriginalListItems] = useState([])
  const [listItems, setListItems] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 6
  const [selectedAudio, setSelectedAudio] = useState(null)
  const [selectedYear, setSelectedYear] = useState(null)
  const [yearsList, setYearsList] = useState([])
  useEffect(() => {
    // Make API call to fetch PDF data from the backend
    fetch("https://main.mzac.org/audios")
      .then((response) => response.json())
      .then((data) => {
        // Reverse the array to display most recent data at the top
        const reversedData = data.reverse()
        setOriginalListItems(reversedData) // Store the original list of items
        setListItems(reversedData)

        // Extract unique years from the data
        const uniqueYears = [...new Set(reversedData.map(item => item.date.split('-')[0]))].reverse()
        setSelectedYear("All") // Set initial selected year
        setYearsList(uniqueYears)
      })
      .catch((error) => console.error("Error fetching data:", error))
  }, []) // Empty dependency array ensures the effect runs only once

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const currentItems = listItems.slice(startIndex, endIndex)

  const goToPage = (page) => {
    setCurrentPage(page)
  }

  const handleYearChange = (event) => {
    const selectedYear = event.target.value
    setSelectedYear(selectedYear)
    if (selectedYear === "All") {
      setListItems(originalListItems) // Reset to the original list when "All" is selected
    } else {
      // Filter the original list based on the selected year
      const filteredItems = originalListItems.filter(item => item.date.startsWith(selectedYear))
      setListItems(filteredItems)
    }
  }

  return (
    <div>
      <div className="big-box">
        <div className="weekly-title">
          <img src={xianlogo} alt="Xianzhifu Logo" />
          <h2>{t("sermon.church")}</h2>
        </div>

        <div className="year-dropdown">
          <select value={selectedYear} onChange={handleYearChange}>
            <option value="All">All</option>
            {yearsList.map((year, index) => (
              <option key={index} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>

        {/* <div className="list-container">
          <p>
            {t("sermon.allAudios")}({listItems.length})
          </p>
          {currentItems.map((item, index) => (
            <div className="list-item" key={index}>
              <div className="left">
                <img src={audio} alt="item" />
                
                <p>{item.title}</p>
                <p>{item.desc}</p>
                <p>{item.date}</p>
             
              </div>

              

              <div className="right">
                <button className="btn btn-translation" onClick={() => setSelectedAudio(item.path)}>
                  {t("sermon.play")}
                </button>
              </div>
            </div>
          ))}
        </div> */}

        <div className="list-container1">

          <div className="list-container">


            <p>
              {t("sermon.allAudios")}({listItems.length})
            </p>
            {currentItems.map((item, index) => (
              <div className="list-item" key={index}>
                <img src={audio} alt="item" />
                <div className="title1">{item.title}</div>
                <div className="desc">{item.desc}</div>
                <div className="date">{item.date}</div>
                <div className="right">
                  <button className="btn btn-translation" onClick={() => setSelectedAudio(item.path)}>
                    {t("sermon.play")}
                  </button>
                </div>
              </div>
            ))}
          </div>

        </div>


        {selectedAudio && (
          <AudioPlayer
            autoPlay
            src={`https://main.mzac.org/audioFile/${selectedAudio}`}
            onEnded={() => setSelectedAudio(null)}
          />
        )}
        <div className="pagi">
          <button
            onClick={() => goToPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            {t("sermon.previous")}
          </button>
          {Array.from(
            { length: Math.ceil(listItems.length / itemsPerPage) },
            (_, i) => (
              <button
                key={i}
                onClick={() => goToPage(i + 1)}
                className={currentPage === i + 1 ? "active" : ""}
              >
                {i + 1}
              </button>
            )
          )}
          <button
            onClick={() => goToPage(currentPage + 1)}
            disabled={
              currentPage === Math.ceil(listItems.length / itemsPerPage)
            }
          >
            {t("sermon.next")}
          </button>
        </div>
      </div>
    </div>
  )
}

// const AudioPage = () => {
//   const [users, setUsers] = useState([]);
//   const [page, setPage] = useState(0);
//   const [limit, setLimit] = useState(10);
//   const [pages, setPages] = useState(0);
//   const [rows, setRows] = useState(0);
//   const [keyword, setKeyword] = useState("");
//   const [query, setQuery] = useState("");
//   const [msg, setMsg] = useState("");
//   const [selectedAudio, setSelectedAudio] = useState(null);

//   useEffect(() => {
//     getUsers();
//   }, [page, keyword]);

//   const getUsers = async () => {
//     const response = await axios.get(
//       `https://main.mzac.org/audios?search_query=${keyword}&page=${page}&limit=${limit}`
//     );
//     setUsers(response.data.result);
//     setPage(response.data.page);
//     setPages(response.data.totalPage);
//     setRows(response.data.totalRows);
//   };

//   const changePage = ({ selected }) => {
//     setPage(selected);
//     if (selected === 9) {
//       setMsg(
//         "If you don't find the data you are looking for, please search for data with specific keywords!"
//       );
//     } else {
//       setMsg("");
//     }
//   };

//   const searchData = (e) => {
//     e.preventDefault();
//     setPage(0);
//     setMsg("");
//     setKeyword(query);
//   };

//   return (
//     <div className="container mt-5">
//       <div className="columns">
//         <div className="column is-centered">
//           <form onSubmit={searchData}>
//             <div className="field has-addons">
//               <div className="control is-expanded">
//                 <input
//                   type="text"
//                   className="input"
//                   value={query}
//                   onChange={(e) => setQuery(e.target.value)}
//                   placeholder="Find something here..."
//                 />
//               </div>
//               <div className="control">
//                 <button type="submit" className="button is-info">
//                   Search
//                 </button>
//               </div>
//             </div>
//           </form>
//           <table className="table is-striped is-bordered is-fullwidth mt-2">
//             <thead>
//               <tr>
//                 <th>ID</th>
//                 <th>Title</th>
//                 <th>Description</th>
//                 <th>Create Date</th>
//                 <th>Play Audio</th>
//               </tr>
//             </thead>
//             <tbody>
//               {users.map((audio) => (
//                 <tr key={audio.id}>
//                   <td>{audio.id}</td>
//                   <td>{audio.title}</td>
//                   <td>{audio.desc}</td>
//                   <td>{audio.date}</td>
//                   <td>
//                   <button onClick={() => setSelectedAudio(audio.path)}>Play</button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//           {selectedAudio && (
//             <AudioPlayer
//               autoPlay
//               src={`https://main.mzac.org/audioFile/${selectedAudio}`}
//               onEnded={() => setSelectedAudio(null)}
//             />
//           )}
//           <p>
//             Total Rows: {rows} Page: {rows ? page + 1 : 0} of {pages}
//           </p>
//           <p className="has-text-centered has-text-danger">{msg}</p>
//           <nav
//             className="pagination is-centered"
//             key={rows}
//             role="navigation"
//             aria-label="pagination"
//           >
//             <ReactPaginate
//               previousLabel={"< Prev"}
//               nextLabel={"Next >"}
//               pageCount={Math.min(10, pages)}
//               onPageChange={changePage}
//               containerClassName={"pagination-list"}
//               pageLinkClassName={"pagination-link"}
//               previousLinkClassName={"pagination-previous"}
//               nextLinkClassName={"pagination-next"}
//               activeLinkClassName={"pagination-link is-current"}
//               disabledLinkClassName={"pagination-link is-disabled"}
//             />
//           </nav>
//         </div>
//       </div>
//     </div>
//   );
// };

export default AudioPage
