// import React, { useState } from 'react';
// import './weekly.css';
// import weekly from '../../Images/weekly.png'
// import read from '../../Images/read.png'
// import download from'../../Images/download.png';
// import xianlogo from '../../Images/xianlogo.png'
// import w240211 from '../../PDF/20240211.pdf';
// import w240204 from '../../PDF/20240204.pdf';
// import w240128 from '../../PDF/20240128.pdf';
// import w240121 from '../../PDF/20240121.pdf';
// import w240114 from '../../PDF/20240114.pdf';
// import w240107 from '../../PDF/20240107.pdf';
// import w231231 from '../../PDF/20231231.pdf';
// import w231224 from '../../PDF/20231224.pdf';
// import w231217 from '../../PDF/20231217.pdf';
// import w231210 from '../../PDF/20231210.pdf';
// import w231203 from '../../PDF/20231203.pdf';

// const Weekly = () => {

//   const listItems = [
//     { image: weekly, date: 'Feb 11, 2024',pdf: w240211 },
//     { image: weekly, date: 'Feb 4, 2024',pdf: w240204 },
//     { image: weekly, date: 'Jan 28, 2024',pdf: w240128 },
//     { image: weekly, date: 'Jan 21, 2024',pdf: w240121 },
//     { image: weekly, date: 'Jan 14, 2024',pdf: w240114 },
//     { image: weekly, date: 'Jan 7, 2024',pdf: w240107 },
//     { image: weekly, date: 'Dec 31, 2023',pdf: w231231 },
//     { image: weekly, date: 'Dec 24, 2023',pdf: w231224 },
//     { image: weekly, date: 'Dec 17, 2023',pdf: w231217 },
//     { image: weekly, date: 'Dec 10, 2023',pdf: w231210 },
//     { image: weekly, date: 'Dec 3, 2023',pdf: w231203 },
//   ];

//   const itemsPerPage = 6;

//   const [currentPage, setCurrentPage] = useState(1);

//   const startIndex = (currentPage - 1) * itemsPerPage;
//   const endIndex = startIndex + itemsPerPage;

//   const currentItems = listItems.slice(startIndex, endIndex);

//   const goToPage = (page) => {
//     setCurrentPage(page);
//   };

//   const handleReadClick = (pdf) => {
//     window.open(pdf, '_blank');
//   };

//   const handleDownloadClick = (pdf) => {
//     const link = document.createElement('a');
//     link.href = pdf;
//     link.download = pdf.substring(pdf.lastIndexOf('/') + 1);
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   return (
//     <div>
//       <div className="big-box">
//         <div className='list-title'>
//         <img src={xianlogo} alt='Xianzhifu Logo'/>
//         <h2>Montreal Zion Alliance Church</h2>
//         </div>
//         <div className="list-container">
//         <p>All Weeklies({listItems.length})</p>
//           {currentItems.map((item, index) => (
//             <div className="list-item" key={index}>
//               <div className="left">
//                 <img src={item.image} alt="item" />
//                 <p>{item.date}</p>
//               </div>
//               <div className="right">
//               <img src={read} alt='Read' onClick={() => handleReadClick(item.pdf)} />
//               <img src={download} alt='Download' onClick={() => handleDownloadClick(item.pdf)} />
//               </div>
//             </div>
//           ))}
//         </div>
//         <div className="pagination">
//           <button onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>
//           Previous
//           </button>
//           {Array.from({ length: Math.ceil(listItems.length / itemsPerPage) }, (_, i) => (
//               <button key={i} onClick={() => goToPage(i + 1)} className={currentPage === i + 1 ? 'active' : ''}>
//               {i + 1}
//               </button>
//           ))}
//           <button onClick={() => goToPage(currentPage + 1)} disabled={currentPage === Math.ceil(listItems.length / itemsPerPage)}>
//           Next
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default Weekly;
import React, { useState, useEffect } from "react"
import "./weekly.css"
import weekly from "../../Images/weekly.png"
import read from "../../Images/read.png"
import download from "../../Images/download.png"
import xianlogo from "../../Images/xianlogo.png"
//import { Worker, Viewer } from "@react-pdf-viewer/core"
import "@react-pdf-viewer/core/lib/styles/index.css"
//import { pdfjs } from "react-pdf"

import { useTranslation } from "react-i18next"

import enTranslation from "../../locales/en.json"
import chTranslation from "../../locales/ch.json"

import i18n from "i18next"
import { initReactI18next } from "react-i18next"

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ch: { translation: chTranslation },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
})

const Weekly = () => {
  const { t } = useTranslation()

  const [originalListItems, setOriginalListItems] = useState([])
  const [listItems, setListItems] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 6
  const [selectedPdf, setSelectedPdf] = useState(null)
  const [selectedYear, setSelectedYear] = useState(null)
  const [yearsList, setYearsList] = useState([])

  useEffect(() => {
    // Make API call to fetch PDF data from the backend
    fetch("https://main.mzac.org/weeklys")
      .then((response) => response.json())
      .then((data) => {
        // Reverse the array to display most recent data at the top
        const reversedData = data.reverse()
        setOriginalListItems(reversedData) // Store the original list of items
        setListItems(reversedData)
        //console.log("Data from API:", data)
        if (data.length > 0) {
          //console.log("Selected PDF Path:", data[0].path)
          setSelectedPdf(reversedData[0].path)
        }
        // Extract unique years from the data
        const uniqueYears = [...new Set(reversedData.map(item => item.date.split('-')[0]))].reverse()
        setSelectedYear("All") // Set initial selected year
        setYearsList(uniqueYears)
      })
      .catch((error) => console.error("Error fetching data:", error))
  }, []) // Empty dependency array ensures the effect runs only once

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const currentItems = listItems.slice(startIndex, endIndex)

  const goToPage = (page) => {
    setCurrentPage(page)
    //setSelectedPdf(null);//
  }

  const handleReadClick = (pdf) => {
    window.open(`https://main.mzac.org/weeklyFile/${pdf}`, "_blank")
    //setSelectedPdf(pdf);
  }

  const handleDownloadClick = (pdf) => {
    const link = document.createElement("a")
    link.href = `https://main.mzac.org/weeklyFile/${pdf}`
    link.download = pdf
    //.substring(pdf.lastIndexOf('/') + 1)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleYearChange = (event) => {
    const selectedYear = event.target.value
    setSelectedYear(selectedYear)
    if (selectedYear === "All") {
      setListItems(originalListItems) // Reset to the original list when "All" is selected
    } else {
      // Filter the original list based on the selected year
      const filteredItems = originalListItems.filter(item => item.date.startsWith(selectedYear))
      setListItems(filteredItems)
    }
  }

  return (
    <div>
      {/* {selectedPdf && (
        <div className="pdf-viewer">
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
            <Viewer file={{ url: `https://main.mzac.org/weeklyFile/${selectedPdf}` }} />
          </Worker>
        </div>
      )} */}
      {/* {selectedPdf && (
        <div className="pdf-viewer" style={{ width: "100%", margin: "auto" }}>
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
          >
            <Viewer
              fileUrl={`https://main.mzac.org/weeklyFile/${selectedPdf}`}
              defaultScale={1.5}
            />
          </Worker>
        </div>
      )} */}
      {selectedPdf && (
        <div className="pdf-viewer" style={{ width: "100%", margin: "auto" }}>
          <iframe
            src={`https://main.mzac.org/weeklyFile/${selectedPdf}`}
            title="PDF Viewer"
            style={{ width: "100%", height: "800px", border: "none" }}
          />
        </div>
      )}
      <div className="big-box">
        <div className="weekly-title">
          <img src={xianlogo} alt="Xianzhifu Logo" />
          <h2>{t("weekly.church")}</h2>
        </div>

        <div className="year-dropdown">
          {/* <select value={selectedYear} onChange={handleYearChange}>
            {listItems.map((item, index) => (
              <option key={index} value={item.date.split('-')[0]}>
                {item.date.split('-')[0]}
              </option>
            ))}
          </select> */}
          <select value={selectedYear} onChange={handleYearChange}>
            <option value="All">All</option>
            {yearsList.map((year, index) => (
              <option key={index} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>

        <div className="list-container">
          <p>
            {t("weekly.allWeeklies")}({listItems.length})
          </p>
          {currentItems.map((item, index) => (
            <div className="list-item" key={index}>
              <div className="left">
                <img src={weekly} alt="item" />
                <p>{item.date}</p>
              </div>
              <div className="right-button">
                <img
                  src={read}
                  alt="Read"
                  onClick={() => handleReadClick(item.path)}
                />
                <img
                  src={download}
                  alt="Download"
                  onClick={() => handleDownloadClick(item.path)}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="pagi">
          <button
            onClick={() => goToPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            {t("weekly.previous")}
          </button>
          {Array.from(
            { length: Math.ceil(listItems.length / itemsPerPage) },
            (_, i) => (
              <button
                key={i}
                onClick={() => goToPage(i + 1)}
                className={currentPage === i + 1 ? "active" : ""}
              >
                {i + 1}
              </button>
            )
          )}
          <button
            onClick={() => goToPage(currentPage + 1)}
            disabled={
              currentPage === Math.ceil(listItems.length / itemsPerPage)
            }
          >
            {t("weekly.next")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Weekly
