import React from "react";
import "./giving.css";
import giving from "../../Images/giving.png";

import { useTranslation } from "react-i18next";

import enTranslation from "../../locales/en.json";
import chTranslation from "../../locales/ch.json";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ch: { translation: chTranslation },
  },
  lng: "ch",
  fallbacklng: "ch",
  interpolation: {
    escapeValue: false,
  },
});

function Give() {
  const { t } = useTranslation();

  return (
    <div className="giving-container">
      <div className="give-image">
        <img src={giving} alt="Giving" />
      </div>
      <div className="giving-context">
        <p>
          <h3>{t("giving.etransfer")}</h3>
          <br></br>
          {t("giving.name")}
          <br></br>
          {t("giving.email")}
          <br></br>
          <br></br>
          {t("giving.info")}
        </p>
      </div>
      <div className="giving-text">
        {t("giving.verse")}
        <br></br>
        <br></br>
        {t("giving.text")}
      </div>
    </div>
  );
}
export default Give;
