import React, { useState } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"

const FormAddVerse = () => {
  const [desc, setDesc] = useState("")
  const [date, setDate] = useState("")
  const [msg, setMsg] = useState("")
  const navigate = useNavigate()

  const saveVerse = async (e) => {
    e.preventDefault()
    try {
      await axios.post("https://main.mzac.org/verses", {
        desc: desc,
        date: date,
      })
      navigate("/admin/verses")
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg)
      }
    }
  }

  return (
    <div>
      <h1 className="title">Verses</h1>
      <h2 className="subtitle">Add New Verse</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={saveVerse}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Description</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                    placeholder="Verse Description"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Date</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    placeholder="Date"
                  />
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormAddVerse
