// import React, { useState } from 'react';
// import './testimonials.css';
// import testimonial1 from '../../Images/testimonial1.jpg';
// import testimonial2 from '../../Images/testimonial2.jpg';
// import testimonial3 from '../../Images/testimonial3.jpg';
// import arrowLeft from '../../Images/arrow-left.png';
// import arrowRight from '../../Images/arrow-right.png';

// function Testimonials() {
//   const [currentIndex, setCurrentIndex] = useState(0);

//   const testimonials = [
//     { image: testimonial1, alt: 'Testimonial1' },
//     { image: testimonial2, alt: 'Testimonial2' },
//     { image: testimonial3, alt: 'Testimonial3' }
//   ];

//   const openNewPage = (url) => {
//     window.open(url, '_blank');
//   };

//   const handlePrev = () => {
//     setCurrentIndex(currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1);
//   };

//   const handleNext = () => {
//     setCurrentIndex(currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1);
//   };

//   return (
//     <div className='testimonial-container'>
//       <p className='testimonial-title'>Testimonial</p>
//       <h3>Our Stories</h3>
//       <div className="testimonial-context">
//         <h4>The most beautiful story is that of God's son, Jesus Christ, who walks with us through the seasons of spring, summer, autumn, and winter.</h4>
//       </div>
//       <div className="testimonial-image">
//         {currentIndex !== 0 && <img src={arrowLeft} alt="Left Arrow" className="arrow-left" onClick={handlePrev} />}
//         <img src={testimonials[currentIndex].image} alt={testimonials[currentIndex].alt} onClick={() => openNewPage(`/testimonial${currentIndex + 1}`)}/>
//         {currentIndex !== testimonials.length - 1 && <img src={arrowRight} alt="Right Arrow" className="arrow-right" onClick={handleNext} />}
//       </div>
//     </div>
//   );
// }

// export default Testimonials;

// import React, { useState, useEffect } from "react"
// import axios from "axios"
// import "./testimonials.css"
// import TestimonialPage from "./TestimonialPage"
// import { Link } from "react-router-dom"
// import arrowLeft from "../../Images/arrow-left.png"
// import arrowRight from "../../Images/arrow-right.png"

// import { useTranslation } from "react-i18next"

// import enTranslation from "../../locales/en.json"
// import chTranslation from "../../locales/ch.json"

// import i18n from "i18next"
// import { initReactI18next } from "react-i18next"

// i18n.use(initReactI18next).init({
//   resources: {
//     en: { translation: enTranslation },
//     ch: { translation: chTranslation },
//   },
//   lng: "en",
//   fallbackLng: "en",
//   interpolation: {
//     escapeValue: false,
//   },
// })

// function Testimonials () {
//   const { t } = useTranslation()
//   const [testimonials, setTestimonials] = useState([])
//   const [currentIndex, setCurrentIndex] = useState(0)

//   useEffect(() => {

//     axios
//       .get("https://main.mzac.org/testimonials")
//       .then((response) => {
//         setTestimonials(response.data)
//       })
//       .catch((error) => {
//         console.error("Error fetching testimonials:", error)
//       })
//   }, []) 

//   const handlePrev = () => {
//     setCurrentIndex(
//       currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1
//     )
//   }

//   const handleNext = () => {
//     setCurrentIndex(
//       currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1
//     )
//   }

//   if (testimonials.length === 0) {
//     return <div>Loading testimonials...</div>
//   }

//   return (
//     <div className="testimonial-container">
//       <p className="testimonial-title">{t("testimonial.testimonial")} </p>
//       <h3>{t("testimonial.ourStories")} </h3>
//       <div className="testimonial-context">
//         <h4>{t("testimonial.description")}</h4>
//       </div>
//       <div className="testimonial-image">
//         {currentIndex !== 0 && (
//           <img
//             src={arrowLeft}
//             alt="Left Arrow"
//             className="arrow-left"
//             onClick={handlePrev}
//           />
//         )}
//         <Link to={`/testimonial/${testimonials[currentIndex].uuid}`}>
//           <img
//             src={`https://main.mzac.org/testimonialFile/${testimonials[currentIndex].path}`}
//             alt={testimonials[currentIndex].title}
//             style={{ width: "600px", height: "500px" }}
//           />
//         </Link>
//         {currentIndex !== testimonials.length - 1 && (
//           <img
//             src={arrowRight}
//             alt="Right Arrow"
//             className="arrow-right"
//             onClick={handleNext}
//           />
//         )}
//       </div>
//     </div>
//   )
// }

// export default Testimonials

import React, { useState, useEffect } from "react"
import axios from "axios"
import "./testimonials.css"
//import TestimonialPage from "./TestimonialPage"
import { Link } from "react-router-dom"
//import arrowLeft from "../../Images/arrow-left.png"
//import arrowRight from "../../Images/arrow-right.png"

import { useTranslation } from "react-i18next"

import enTranslation from "../../locales/en.json"
import chTranslation from "../../locales/ch.json"

import i18n from "i18next"
import { initReactI18next } from "react-i18next"

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ch: { translation: chTranslation },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
})

function Testimonials () {
  const [testimonials, setTestimonials] = useState([])
  const [displayedTestimonials, setDisplayedTestimonials] = useState([])
  const [displayedTestimonials2, setDisplayedTestimonials2] = useState([])
  const [showMore, setShowMore] = useState(false)
  const { t } = useTranslation()

  const [startIndex, setStartIndex] = useState(0)

  useEffect(() => {

    axios
      .get("https://main.mzac.org/testimonials")
      .then((response) => {

        const reversedTestimonials = response.data.slice()
        setTestimonials(reversedTestimonials)
        setStartIndex(
          reversedTestimonials.length >= 3 ? reversedTestimonials.length - 3 : 0
        )
      })
      .catch((error) => {
        console.error("Error fetching data:", error)
      })
  }, [])
  useEffect(() => {
    setDisplayedTestimonials(testimonials.slice(startIndex, startIndex + 3).reverse())
  }, [startIndex, testimonials])
  useEffect(() => {
    setDisplayedTestimonials2(testimonials.slice(startIndex, startIndex + 6).reverse())
  }, [startIndex, testimonials])


  const handleShowMore = () => {
    const last6Testimonials = testimonials.slice(-6).reverse()
    setDisplayedTestimonials(last6Testimonials)
  }

  const handlePrevTestimonials = () => {
    const newIndex = Math.max(0, startIndex - 3)
    setStartIndex(newIndex)
  }

  const handleNextTestimonials = () => {
    const newIndex = Math.min(testimonials.length - 3, startIndex + 3)
    setStartIndex(newIndex)
  }

  return (
    <div className="testimonial-container">
      {/* <p className="testimonial-title">{t("testimonial.testimonial")} </p> */}
      <h3>{t("testimonial.ourStories")} </h3>
      <div className="testimonial-context">
        <h4>
          {t("testimonial.description")}
        </h4>
      </div>
      <div className="show-more-container">
        {startIndex + 3 < testimonials.length && (
          <div className="arrow-btn left-arrow" onClick={handleNextTestimonials}>
            {"<"}
          </div>
        )}
        <div className="card-container">
          {displayedTestimonials.map((testimonial) => (
            <div className="card" key={testimonial.uuid}>

              <Link to={`/testimonial/${testimonial.uuid}`}>
                <img
                  src={`https://main.mzac.org/testimonialFile/${testimonial.path}`}
                  alt="Testimonial"
                  style={{
                    width: "300px",
                    height: "auto",
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                />
              </Link>
              <h4>{testimonial.title}</h4>
              <h5>{testimonial.date}</h5>
              <hr />
            </div>
          ))}
        </div>

        {/* Left and Right Arrows */}

        {startIndex > 0 && (
          <div className="arrow-btn right-arrow" onClick={handlePrevTestimonials}>
            {">"}
          </div>
        )}
      </div>
      {testimonials.length > 3 && displayedTestimonials.length < 6 && (
        <div className="show-more-btn" onClick={handleShowMore}>
          {t("home.showMore")}
        </div>
      )}


    </div>
  )
}

export default Testimonials