import React, { useState, useEffect } from "react"
import axios from "axios"
import { useNavigate, useParams } from "react-router-dom"

const FormEditVerse = () => {
  const [desc, setDesc] = useState("")
  const [date, setDate] = useState("")
  const [msg, setMsg] = useState("")
  const navigate = useNavigate()
  const { id } = useParams()

  useEffect(() => {
    const getVerseById = async () => {
      try {
        const response = await axios.get(`https://main.mzac.org/verses/${id}`)
        setDesc(response.data.desc)
        setDate(response.data.date)
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg)
        }
      }
    }
    getVerseById()
  }, [id])

  const updateVerse = async (e) => {
    e.preventDefault()
    try {
      await axios.patch(`https://main.mzac.org/verses/${id}`, {
        desc: desc,
        date: date,
      })
      navigate("/admin/verses")
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg)
      }
    }
  }

  return (
    <div>
      <h1 className="title">Verses</h1>
      <h2 className="subtitle">Edit Verse</h2>
      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form onSubmit={updateVerse}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Description</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                    placeholder="Verse Description"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Date</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    placeholder="Date"
                  />
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormEditVerse
