import React from "react";
import "./alpha.css";
import alpha from "../../../Images/alpha.png";
import TextSphere from "./TextSphpere.js";

import { useTranslation } from "react-i18next";

import enTranslation from "../../../locales/en.json";
import chTranslation from "../../../locales/ch.json";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ch: { translation: chTranslation },
  },
  lng: "ch",
  fallbacklng: "ch",
  interpolation: {
    escapeValue: false,
  },
});

function Alpha() {
  const { t } = useTranslation();

  const openYouTubeLink = () => {
    window.open("https://www.youtube.com/watch?v=hBMMD5C0k-s&t=123s", "_blank");
  };

  return (
    <div className="alpha-container">
      <TextSphere />
      <div className="alpha-context">
        <p>
          {t("alpha.welcome")}
          <br />
          {t("alpha.description")} <br />
          {t("alpha.join")}
        </p>
      </div>
    </div>
  );
}

export default Alpha;
