import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import axios from "axios"

const TestimonialList = () => {
  const [testimonials, setTestimonials] = useState([])
  const [msg, setMsg] = useState("")

  useEffect(() => {
    getTestimonials()
  }, [])

  const getTestimonials = async () => {
    const response = await axios.get("https://main.mzac.org/testimonials")
    setTestimonials(response.data)
  }

  const deleteTestimonial = async (testimonialId) => {
    try {
      await axios.delete(`https://main.mzac.org/testimonials/${testimonialId}`)
      getTestimonials()
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg)
      }
    }
  }

  return (
    <div>
      <h1 className="title">Testimonial</h1>
      <h2 className="subtitle">List of Testimonial</h2>
      <Link to="/admin/testimonials/add" className="button is-primary mb-2">
        Add New
      </Link>
      <p className="has-text-centered">{msg}</p>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Title</th>
            <th>Description</th>
            <th>Created Date</th>
            <th>File Name</th>
            <th>Created By</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {testimonials.map((testimonial, index) => (
            <tr key={testimonial.uuid}>
              <td>{index + 1}</td>
              <td>{testimonial.title}</td>
              <td>{testimonial.desc}</td>
              <td>{testimonial.date}</td>
              <td>{testimonial.path}</td>
              <td>{testimonial.user.name}</td>
              <td>
                <Link
                  to={`/admin/testimonials/edit/${testimonial.uuid}`}
                  className="button is-small is-info"
                >
                  Edit
                </Link>
                <button
                  onClick={() => deleteTestimonial(testimonial.uuid)}
                  className="button is-small is-danger"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TestimonialList
