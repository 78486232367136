import React from "react";
import { Link } from "react-router-dom";
import xianlogo from "../../Images/logosmall.png";

import { useTranslation } from "react-i18next";

import enTranslation from "../../locales/en.json";
import chTranslation from "../../locales/ch.json";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ch: { translation: chTranslation },
  },
  lng: "ch",
  fallbacklng: "ch",
  interpolation: {
    escapeValue: false,
  },
});

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer
      style={{
        backgroundColor: "#e7e7e7",
        color: "#000",
        padding: "20px",
        marginTop: "180px",
      }}
    >
      <h5
        style={{
          textAlign: "center",
          marginTop: "40px",
          marginBottom: "20px",
          fontWeight: "bold",
          letterSpacing: "1.3px",
        }}
      >
        {t("footer.welcome")}
      </h5>

      <Link className="nav-link" to="/admin">
        <img src={xianlogo} alt="Xi'an Logo"
           style={{
            display: "block",
            margin: "auto",
        }}
        />
      </Link>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row", // Default to row layout
          flexWrap: "wrap", // Allow wrapping on smaller screens
          marginTop: "40px",
        }}
      >
        <div
          style={{
            marginLeft: "100px",
            marginRight: "100px",
            marginBottom: "20px", // Add margin-bottom for spacing
            flex: "1 0 300px", // Limit width to prevent overflow
            minWidth: "300px", // Set minimum width
          }}
        >
          <p>{t("footer.address")}</p>
          <p>4824 Cote-des-Neiges, #301, Montreal, QC H3V 1G4</p>
        </div>
        <div
          style={{
            marginLeft: "100px",
            marginRight: "100px",
            marginBottom: "20px", // Add margin-bottom for spacing
            flex: "1 0 300px", // Limit width to prevent overflow
            minWidth: "300px", // Set minimum width
          }}
        >
          <p>{t("footer.sundayWorship")}</p>
          <p>{t("footer.11")}</p>
        </div>
        <div
          style={{
            marginLeft: "100px",
            marginRight: "100px",
            flex: "1 0 300px", // Limit width to prevent overflow
            minWidth: "300px", // Set minimum width
          }}
        >
          <p>{t("footer.contact")}</p>
          <p>{t("footer.email")}</p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <p style={{ fontSize: "14px", marginTop: "80px" }}>
          {t("footer.allRights")}
        </p>
      </div>
    </footer>
  );
};
export default Footer;


