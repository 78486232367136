import React from "react";
import "./pastoral.css";
import ImageCard from "../../../Components/ImageText&Card/imageCard";
import Jacob from "../../../Images/Jacob.jpg";
import Xianhua from "../../../Images/Xianhua.jpg";
import Dongzhuo from "../../../Images/Dongzhuo.jpg";

import { useTranslation } from "react-i18next";

import enTranslation from "../../../locales/en.json";
import chTranslation from "../../../locales/ch.json";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ch: { translation: chTranslation },
  },
  lng: "ch",
  fallbacklng: "ch",
  interpolation: {
    escapeValue: false,
  },
});

function Pastoral() {
  const { t } = useTranslation();

  const cardData = [
    { imageSrc: `${Jacob}`, heading: t("team.pastor"), heading2: t("team.ma") },
    {
      imageSrc: `${Xianhua}`,
      heading: t("team.worship"),
      heading2: t("team.yao"),
    },
    {
      imageSrc: `${Dongzhuo}`,
      heading: t("team.children"),
      heading2: t("team.wang"),
    },
  ];

  return (
    <div>
      <h4 className="pastor-title">{t("team.title")}</h4>
      <div className="cards">
        <div className="card-container">
          {cardData.map((card, index) => (
            <ImageCard
              key={index}
              imageSrc={card.imageSrc}
              heading={card.heading}
              heading2={card.heading2}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Pastoral;
