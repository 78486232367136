import React, { useEffect } from "react";
import Layout from "./Layout";
import FormEditTestimonial from "../Components/Components/FormEditTestimonial";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../Components/Components/features/authSlice";
import "bulma/css/bulma.css";

const EditTestimonial = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/admin");
    }
  }, [isError, navigate]);
  return (
    <Layout>
      <FormEditTestimonial />
    </Layout>
  );
};

export default EditTestimonial;
