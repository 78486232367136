import React from 'react'
import './testimonial2.css';
import testimonial2s from '../../../Images/testimonial2s.jpg'

function Testimonial2() {
  return (
    <div className='testimonial2-container'>
      <p className='testimonial-title'>Testimonial</p>
      <h2>Our Stories</h2>
      <div className="testimonial-context">
        <h4>The most beautiful story is that of God's son, Jesus Christ, who walks with us through the seasons of spring, summer, autumn, and winter.</h4>
      </div>
      <div className="testimonial2-image">
        <img src={testimonial2s} alt='Testimonial2 Small'/>
        <img src={testimonial2s} alt='Testimonial2 Small'/>
        <img src={testimonial2s} alt='Testimonial2 Small'/>
      </div>
      <div className="testimonial1-context">
        
        <p>我的名字叫Nancy，来自四川成都，生有一子，未得救前是无任何信仰。<br></br>

这一年，我结束了我12年的婚姻，前夫为了追求他认为的更好的生活而选择离婚。经过他长达三个月各种方式的逼离后，我也选择停止内耗同意离婚。曾经为了孩子苦苦坚守的婚姻结束了，这个过程，很痛！我的父母看着我整天痛哭流涕的样子，他们也倍受煎熬。
<br></br>
我开始出去工作，37岁的我在职场上早已经没有了竞争力。因为以前学过美甲，所以靠着这门手艺找到了工作。工资不高，但总算能让自己忙起来，不去想那些痛苦的回忆！
<br></br>
有一天我突然在抖音上看见一个也是来自成都的基督徒妈妈离婚后，一个人带着两个孩子去了美国。她的两个儿子当年也是在美国生的，她和我的情况有些相像。于是我与她取得了联系，她也鼓励我带着孩子出去，她告诉我美甲在美国很好找工作，工资待遇也不低。
<br></br>
于是我萌生了带着孩子去美国的想法，刚好我有一个很好的女性基督徒朋友在密歇根州，所以我决定去那里。朋友把我孩子的学校也申请好了，可是经过一番详细的了解后，她的孩子因为是美宝，他可以在美国长期居住，但我不行，最多能呆6个月。我出国唯一的办法就是申请难民身份，但那预示着我可能以后都回不了中国了，见父母也是很难了！所以我放弃了去美国的想法。
<br></br>
我接着了解到加拿大的留学移民相对容易些，我也有亲戚在加拿大，所以就开始收集各种加拿大的信息。我在5月份开始办理签证，然后办理各种出国的手续和准备需要用到的资料。这个过程对于一个单亲妈妈也是很艰难的，因为有移民倾向，所以需要更多的补充材料。那段时间我几乎都在紧张焦虑中度过！担心哪一个环节出现了问题，整个计划就失败了！之前所有的努力也就白费了！
<br></br>
但很幸运，手续一步一步的都顺利办理下来了。出发前的几天，我在一个加拿大入境群里认识了教会里的佩佩妈妈，她得知我们是同一个航班去温哥华，于是我们互相加了微信，后面更惊讶得知我们最终目的地都是蒙特利尔，而且又是同一个航班！真的很巧！
<br></br>
我们见面后一起聊天，她听说我住西岛，她说她也有一个朋友住在西岛，是教会里的朋友，他们每周都会有聚会。我当时没在意这事，只是想着以后她来西岛的时候我们有机会可以见面聚一聚。
<br></br>
我到了蒙特利尔安顿下来后，我带着孩子去看望在渥太华的姑姑。她是二十多年很虔诚的基督徒，她建议我在蒙特利尔寻找一个教会，了解一下基督教，这会对我目前的状态有所帮助！
<br></br>
就这样我又联系佩佩妈妈，她把在西岛的马弟兄微信推了给我。感谢主！当天我就和马弟兄联系上了，他非常热情，说周四就有一个聚会是在南岸，邀请我去参加。他开车来接我和我的孩子，就这样我走近了神！
<br></br>
自从离婚以来，我丧失了我的自信，我一直认为现在的我是不够体面、俊美的，但当马弟兄讲到哥林多前书十二章，神说身上肢体，我们看为不体面的，神越发给他加上体面；我们看为不俊美的，神越发使他得着俊美。
<br></br>
我突然感受到这就是神给予我们的爱。我很感恩，在我最缺爱最自卑的时候得到了上帝的爱！得到了兄弟姐妹的爱！原来我是被爱着的！
<br></br>
到加拿大一个月之后， 我和孩子得了新冠，在一个全新的环境下，语言能力有限的环境下，我和孩子感觉到很无助。就在这个时候，接到了马弟兄的电话，他和他的夫人正在我家门口，她们给我送了一些食物放在房门前。这个举动对当时的我起来说真是雪中送炭，突然感受到了被爱充满！感受到了基督徒美好行为和品行的彰显，他们给周围的人带来了盼望、满足。
<br></br>
为了解决生计问题，我开始寻找兼职工作，求神助攻，我顺利的找到了第一份兼职，后来又找到了第二份。两份工作都很满意，我相信这都是神的安排，是主让我从过去痛苦的婚姻中解脱出来，重新认识了自己。虽然独自带着孩子生活很难，但我相信所有发生的一切都有神的美意。
<br></br>
感谢主，我越来越相信我是被神在万民中挑选的。是神让我认识这些弟兄姐妹，当我在逆境中，引领我去认识祂，信靠祂。.在主内得到我重生的新生命！
<br></br>
感谢主，阿门！
<br></br>
2022-12-26
</p>
      </div>
    </div>
  )
}
export default Testimonial2