import React, { useState } from "react";
import "./socialconcern.css";
import socialconcern1 from "../../../Images/Socialconcern1.png";
import socialconcern2 from "../../../Images/Socialconcern2.jpg";
import arrowLeft from "../../../Images/arrow-left.png";
import arrowRight from "../../../Images/arrow-right.png";

import { useTranslation } from "react-i18next";

import enTranslation from "../../../locales/en.json";
import chTranslation from "../../../locales/ch.json";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ch: { translation: chTranslation },
  },
  lng: "ch",
  interpolation: {
    escapeValue: false,
  },
});

function SocialConcern() {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);

  const socialconcerns = [
    { image: socialconcern1, alt: "Socialconcern1" },
    { image: socialconcern2, alt: "Socialconcern2" },
  ];

  const openNewPage = (url) => {
    window.open(url);
  };

  const handlePrev = () => {
    setCurrentIndex(
      currentIndex === 0 ? socialconcerns.length - 1 : currentIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex(
      currentIndex === socialconcerns.length - 1 ? 0 : currentIndex + 1
    );
  };

  return (
    <div className="testimonial-container">
      <p className="testimonial-title">{t("socialConcern.socialconcern")}</p>
      <h3>{t("socialConcern.ourActivities")}</h3>
      <div className="testimonial-context">
        <h4>{t("socialConcern.verse")}</h4>
      </div>
      <div className="testimonial-image">
        {currentIndex !== 0 && (
          <img
            src={arrowLeft}
            alt="Left Arrow"
            className="arrow-left"
            onClick={handlePrev}
          />
        )}
        <img
          src={socialconcerns[currentIndex].image}
          alt={socialconcerns[currentIndex].alt}
          onClick={() => openNewPage(`/socialconcern${currentIndex + 1}`)}
        />
        {currentIndex !== socialconcerns.length - 1 && (
          <img
            src={arrowRight}
            alt="Right Arrow"
            className="arrow-right"
            onClick={handleNext}
          />
        )}
      </div>
    </div>
  );
}

export default SocialConcern;
