import React from 'react';
import './kids.css';
import { Link } from 'react-router-dom';
import sgtv from '../../../../Images/sgtv.png';
import forkids from '../../../../Images/For-Kids.png';
import keysforkids from '../../../../Images/keysforkids.png';
import truthforkids from '../../../../Images/truthforkids.png';
import YouTubeforkids from '../../../../Images/YouTubeforkids.png';
import kidsonthemove from '../../../../Images/kidsonthemove.png';
import saddlebackkids from '../../../../Images/saddlebackkids.png';
import lifetreekids from '../../../../Images/lifetreekids.png';
import back from '../../../../Images/BACK.png';

function Kids() {
  return (
    <div className='kid-container'>
      <div className="kid-title">
        <img src={forkids} alt='For Kids' />
      </div>
      <div className="kid-image">
        <Link to="https://skitguys.com/sgtv/show/bedtime-bible-stories-with-the-skit-guys"> 
          <img src={sgtv} alt='Bedtime Bible Stories' />
        </Link>
        <Link to="https://www.keysforkids.org/Programming/Keys-for-Kids-Daily-Devotional/Read-Listen"> 
          <img src={keysforkids} alt='Keys for Kids Devotion' />
        </Link>
        <Link to="https://truthforkids.com/devotional/#.Xm1ylMjYqt0"> 
          <img src={truthforkids} alt='Truth for Kids Devotion' />
        </Link>
      </div>
      <div className="kid-title">
        <img src={YouTubeforkids} alt='YouTube Channels For Kids' />
      </div>
      <div className="kid-image">
        <Link to="https://www.youtube.com/channel/UCqcIv3r3KZGV25qn5KqjqLg"> 
          <img src={kidsonthemove} alt='Kids on the Move' />
        </Link>
        <Link to="https://www.youtube.com/channel/UCEbM1vIWVubIANRR54YuS0g"> 
          <img src={saddlebackkids} alt='Saddleback Kids' />
        </Link>
        <Link to="https://www.youtube.com/channel/UCIlweo9SxziGxoi16kE4HEQ"> 
          <img src={lifetreekids} alt='Lifetree Kids' />
        </Link>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <Link to="/Children"> 
          <img src={back} alt='BACK' />
      </Link>
    </div>
  );
}

export default Kids;


