import React, { useEffect, useRef } from 'react';
import './textshpere.css';
import TagCloud from 'TagCloud';

const TextSphere = () => {
    const tagCloudRef = useRef(null);

    useEffect(() => {
        if (tagCloudRef.current && tagCloudRef.current.children.length === 0) {
            const container = tagCloudRef.current;
            const texts = [
                "GOD", "Love", "Care", "Jesus", "Faith", "Hope", "Mercy", "Joy", "Blessing", "Forgiveness", "Compassion",
                "Heaven", "Spirit", "Life", "Light", "Salvation", "Wisdom", "Holy", "Grace", "Courage", "Bible", "Trust", "rescue"
            ];
            const options = {
                radius: 300,
                maxSpeed: "normal",
                initSpeed: "normal",
                keep: true,
            };
            TagCloud(container, texts, options);
        }

        return () => {
            // Cleanup function
        };
    }, []);

    return (
        <div className='text-sphere-container'>
            <div ref={tagCloudRef} className='tagcloud'></div>
        </div>
    );
}
export default TextSphere;



