import React from "react";
import "./history.css";
import Simpson from "../../../Images/Simpson.png";
import fiveyear from "../../../Images/5 years.png";
import fiveyearcontent from "../../../Images/fiveyearcontent.png";

import { useTranslation } from "react-i18next";

import enTranslation from "../../../locales/en.json";
import chTranslation from "../../../locales/ch.json";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ch: { translation: chTranslation },
  },
  lng: "ch",
  fallbacklng: "ch",
  interpolation: {
    escapeValue: false,
  },
});

function History() {
  const { t } = useTranslation();

  return (
    <div className="history-container">
      <div className="history-image">
        <img src={Simpson} alt="Simpson" />
      </div>
      <div className="history-context">
        <p>{t("history.description")}</p>
      </div>
      <div className="story-image">
        <img src={fiveyear} alt="FiveYear" />
      </div>
      <div className="story-image">
        <img src={fiveyearcontent} alt="FiveYear Content" />
      </div>
    </div>
  );
}
export default History;
