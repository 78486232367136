import React from 'react'
import './testimonial3.css';
import testimonial3s from '../../../Images/testimonial3s.jpg'

function Testimonial1() {
  return (
    <div className='testimonial3-container'>
      <p className='testimonial-title'>Testimonial</p>
      <h2>Our Stories</h2>
      <div className="testimonial-context">
        <h4>The most beautiful story is that of God's son, Jesus Christ, who walks with us through the seasons of spring, summer, autumn, and winter.</h4>
      </div>
      <div className="testimonial3-image">
        <img src={testimonial3s} alt='Testimonial3 Small'/>
        <img src={testimonial3s} alt='Testimonial3 Small'/>
        <img src={testimonial3s} alt='Testimonial3 Small'/>
      </div>
      <div className="testimonial3-context">
        
        <p> 今年(2022)年初，我特别的低沉与抑郁，对学校生活、技能学习、未来方向的迷惘使我悲伤、哀恸。我怀疑自己的一切，可我并没有像许多人一样试着轻生，因为我不甘于如此懦弱的结束我这尚未开始的生命。那段时间里，我天天浸泡在图书馆，过往的朋友也断了联系，因我满脸尽是悲观，充满了叔本华的抑郁气质，不知该说是艺术感还是单纯的丧呢？从弗洛伊德到阿德勒，一学期的心理学使我明白了心理学救不了我的病，这是一种灵魂在啜泣的病，不由得肤浅的心理学来治。
<br></br><br></br>
对心理学失去了兴趣，我便开始阅读现代哲学与日本短片小说，从尼采到萨特，从叔本华到卢梭，芥川龙之介到太宰治，这些人类思想的天才巨匠并非没有解决孤独带来的问题，反而使我的悲观主义色彩变得越发浓烈了。“人生实如钟摆，在痛苦与倦怠中徘徊”叔本华一言便道破了我的处境。我想要成为最好，各方面都要超越别人，音乐、体育、思想。可每当我完成我给自己定下的那些艰难的目标时，我便会倦怠，不明白努力的意义适合，便痛苦了起来。我的音乐已经超过绝大多数人了，对音符的理解，对技巧的运用，可我仍不满意，我想变更好，我追求卓越与蔑视，蔑视所有没我有天赋的人，所有没我努力的人。我望着镜子中的身材，已经是大多数人一辈子都无法拥的了，可我不满意，我还可以练得更努力，更好看，更有力量。我听着旁人的夸赞，可我却丝毫不当回事，因为我知道天才离他的极限还十分遥远，朋友与家人都对我的抑郁很是厌烦与疑惑，我各方面都远超正常人，甚至住着一般人一辈子都买不起的房子，是不是太过脆弱了？没有过过苦日子？无人懂这种孤独，渴望超越的灵魂。我便沉沦了。。。。
<br></br><br></br>
 孤独、脆弱、敏感、痛苦，皆被成为无病呻吟。当家中只剩下我一人时，我明白了世俗的空虚与无意义，贵重的物品只会麻痹一人的身体，却触动不了他的灵魂，红木家具带给我的只有压抑。父母尽管退休了，可仍会为了琐事争吵，甚至为了几块钱的油价而郁闷一整天。我的意义为何?这不光我们家如此，所有山上的邻居都这样。各种不合，小孩，教育，家务，可他们不敢离婚，他们舍不得家产，拼命打拼了大半辈子的钱怎么能说分半就分半呢？于是争吵继续。。。。
 <br></br><br></br>
  我看着他们有钱人的生活得出了钱没有意义这个想法，可什么有意义呢？他人的夸赞？人际关系? 学业？我当时认为一切都没有意义，都是虚无。我母亲在那段时间见到我便说我特别的阴沉，令人发怵，所言甚是，我当时满腹尽是悲伤，丝毫看不到世界的一丝曙光。“他人即地狱”我的确这样认为，因为家人不理解我，朋友嫌弃我的“懦弱”.我的气息使靠近我的人心里发寒，不知如何与我交谈。我渴望社交，可我害怕拒绝，害怕失去，害怕被抛弃，从小到大也为了进步，而从没体验过青春，没尝过暗恋的刺激，单相思的伤心，两情相悦的甜蜜，分手的心碎。在别人与暗恋女生发消息时，我在琴房；在别人与朋友聚会时，我在健身房；在别人喝酒唱歌时，我在图书馆。没有共同话题，没有交际，与同龄人的聊天气氛尽是尴尬，于是我认为他人即地狱。。。。。
  <br></br><br></br>
   孤零零的站在球场，只有风的叹息时不时地试着跟我诉说世人如何忽视着它带来的凉爽与清香，而只记得它带来的灾难。我不太能懂，可我的心似乎有些被触动，它问风“明明我那么温柔，那么包容，那么尊重，可为何旁人尽说我软弱，没主见？明明我那么坚强，那么高傲，可为何旁人却说我脆弱敏感？明明我每一次分别都忍不住哭，想给对方留下一张笑容，可为何旁人说我冷酷无情?”风回答“人类只会记住表面，他们宁愿相信装出来的魔鬼，也不愿相信真正关心他们，可是不是关心过度了的天使，白昼的光，如何了解夜晚黑暗的深度呢?”我的反问道”我也那么肤浅吗？没看透爱我的人吗？“风没有回答便匆匆离开了。。。。
   <br></br><br></br>
 我开始慢慢地以一种乐观的眼光看待周围的人和事。我不再沉浸在自己的世界，我不再暗自流泪，我不再逃避自己带刺的心，不再畏惧人际关系，不再渴求怜悯。。。。曾经的我与抑郁情绪培养出了一种难以言喻的情感，厌恶、恐惧、并留恋着，若悲观想法模糊了，我则感觉整个人顿时空虚了起来，体验不到任何情绪，落入吞噬一切的黑洞之中。潜意识的我希望被抑郁想法所笼罩，因为它至少让我有存在于这个世界的感觉，让我轻微的认识到我是给有情感、有温度的人。
 <br></br><br></br>
  可这种情绪被继成大叔带到教会后，不久便逐渐消失了。我明白我一直缺少的是信仰，用以填补我心的空缺。上帝不会像人类父母一样，小孩不够聪明伶俐，便悉悉数落他。人类的功利只会让社会进入恶性竞争，忘了感情应有的纯粹。基督让我认识到人理性的局限性：人是无法完全控制自己的，尽管他认为他很理性了。在此期间，我不再害怕他人，开始敢于交流，可总有一些悲伤阴魂不散，我

不知道为什么。有些鸟被戳瞎了眼睛，这样他们的歌声就更加动听。我不认为今天的螺丝们会唱歌，但我们的确被弄瞎了，被过于明亮又过于突然和耀眼的光芒弄瞎，再也看不见其他的道路了。我们一个个排着队，进入培养“教育“我们的牢笼，被周围人踢着穿越数千

年，走马观花般的吸收着人类数千年的沉淀，匆匆跑去画廊只是为了给自己添上一份艺术气息，匆匆去听音乐会，匆匆地休息，逐渐丧失生命。我们无家可归，因为人类文明的各种观念与道德在他们心中纠缠，因为我们知道”每个时代各不相同，而他们是什么，重要吗？“
<br></br><br></br>
 当有一天，人类刚出生便已是满头白发，一出生就望穿尽头，我们本能的坚信着，人类已处于老年，回首着过去的“甘甜“，在历史中寻求安慰，这与电子游戏有何区别呢？我不会去相信世界末日即将来临，因为我没有资格对我们的世代做下末日的审判，这是上帝的事。世俗生命的一切意义，如果是等待终结，那么所有朝向未知领域的飞翔都将不存在。“终有一死“这样的宿命感可以让我们活得更平稳、更安宁、更幸福，可背后也同时藏着一种深深的绝望感，使更高的教育得以播种，大胆的尝试都黯然无光了，人类历史将重回中世纪的沉眠。这样拒绝新生事物与播种的观念会让孩子一出生就长满了白发，让他们感觉到一切的行动都是无意义、无价值的，因为

世界已经为审判做好准备。
<br></br><br></br>
  深入的思考不是怀疑与不敬。因为如果不准做多余的思考的话，如果只是一味的接受的话，那么年轻人便不知道做什么了，只好试着了解过去发生的事。当已经创造出来历史的仆人，在渺小中度过世俗的一生，我们的梦想其实已经在这个时代实现了。“过着舒适的生活，在工作之余还有空闲做自己喜欢的事“可我不愿意满足平庸，一个群体对平庸的满足比自杀还可怕。我还没成熟，无法和乐融融地活在虚无的美满中。我相信神会让我们有一个更灿烂的时代，为此需要抛弃对科学的崇拜，对演化论的信仰(人与动物并无根本差异的观点)，需要有在伟大崇高的事上分深碎骨的勇气，需要有对天父对他人的爱。
  <br></br><br></br>
  可光靠自己是没办法拥有美德的，只有在天父的慈爱之下，在认识到耶稣的完美之后，我才能认识到我的不足、我的罪恶，但却丝毫不伤心、不悲观，因为我知道天父会赦免我已犯下的罪，并永恒地爱着我。
  <br></br><br></br>
   我以前认为其他人都很可怕，每个人都像是只刺猬，想要接近彼此，互相拥抱，可靠的太近却会扎伤彼此。我认为每个人都是独立的个体，他人也因此不能明白。我一直是个偏理性的人，在许多事上都会问为什么，应算是个怀疑主义者。可我明白了，怀疑论者是没有办法获得内心的平静的，应当折中。世界上有许多证明不了的事，若我对所有事都抱有怀疑态度，那么将没有确定的事物，这会将人带往虚无。相信并不是论证，而是爱。我相信正义是美德，那么它就是。我们无法证明正义的存在，也无法证明它是好是坏，但只要相信就足够了。人的生命是需要希望才可以得以维持的，可希望也是我们这个世代正缺失的。当代人已经无法承受科学带给我们的无意义了。如此多的知识是以前人根本无法想象的。当我走进图书馆，几百万本书陈列在我眼前，从代数到微积分，化学到量子力学，无一不在向我诉说着宇宙的真理，人类的渺小，有些人选择视而不见，活在自己狭小的井中，坐井观天。有人奋力爬出井，想要窥看真正天的广阔，他们成功了，知道了地球只是银河中的一粒沙子，而自己只是地球上一粒沙子而已。
   <br></br><br></br>
   “我想把真相告诉大家，尽管无人相信这个世界只是一本书，我们扮演着各种角色，谁也无法改变自己的命运。冥冥之中，已经注定结局，正如每一个让你都会死亡，无法更改
   <br></br><br></br>
   “当今社会越来越多的人选择“躺平“，也就是因为无意义感。一个个懦弱的人，只想活在他们的异次元世界，他们宁愿接受虚无缥缈的幻想，将自己当中电子游戏中无所不能的角色，当作动漫中呼风唤雨的人物，也不愿被残酷的现实所困扰。
   <br></br><br></br>
   这就是无节制的暴饮暴食，被强迫吸收过多的知识带来的结果。可胃能将多余的食物吐出来，可大脑不行，毫无节制的理性、科学、历史，如果被推到它的逻辑顶点，就会彻底毁掉未来，因为它摧毁了希望和幻想。知识的公正与客观会破坏掉活生生的东西。
   <br></br><br></br>
  当代许多人将宗教当作一种历史知识，并对其做一堆科学研究，这些人根本没搞清楚本质。历史的审核会把宗教的错误荒谬、残暴冷酷揭露出来，理性与科学会使虔诚与希望变得支离破碎。可美好的事物只有在虔诚希望之中才能存活。
  <br></br><br></br>
  基督教是有生命的。任何有生命的东西被完全解剖了以后，它就失去了生命。当人类开始解剖宗教时，他们就注定活在苦恼与痛苦之中了。
  <br></br><br></br>
 我只有借助爱，怀着对耶稣无条件的信仰时，我才会有力量，才会创造出美好的事物。我生于一个属于能对整个国家最有用的工作的时代。一个个螺钉按照时代的需要被塑造出来，这样就能很快在社会中各就各位。我也是其中之一。
 <br></br><br></br>
我的感受、我的情绪，鲁迅先生所写的“人类的悲欢并不相通，我只觉得他们吵闹”,深入我心，我也在许久时间将其看作真理。可在认识主以后，我看待与对待他人的方式变了。我看到了每个人对我的用心，对我的好，我才意识到我以前是多么的愚昧。我开始对每个人微笑，对家人与朋友更关心，更愿意花时间。就算遇到了狗眼看人低，满口甜言，见不得人好的人，我也更静的下心与他们交谈，试着理解他们的难处。天父让我知晓了善恶美丑的本来面目。这些人都和我一样，尽管做错了事，他们也还是同我血脉相连。我以前也是他们的其中一员，可丑行并不是我们无法摆脱的东西。所以我不会因为这些同胞犯了错误便心存怨气。耶稣让我明白了宽恕与仁爱。祂用祂的生命向我展现了宽恕应当是怎样的。
<br></br><br></br>
  我也不再像以前一样一直猜测他人心里想什么了。因为这只会让我感到烦躁和心神不宁。当我心中充满爱的时候我不需要猜疑，我与他人的关系也便好了许多。我明白了在与人的关系这不能违背诺言，不能丧失自尊，不能陷入憎恨，不能伪善。认识到这些，那我便没有必要猜测对方的内心了。
  <br></br><br></br>
  最为主要的改变之一，便是我有了勇气。我很喜欢一句话“Courage is the only virtue you cannotfake .“【编者：勇气是你唯一无法伪造的美德】我明白也懂了。我不会可以追求死亡，可我不会在死亡面前当逃兵，无论寿命或长或短，我都无留恋，应坦然面对，表现出一副体面又自然的样子。当命运招呼我离开人世的时候，我的人生早已功德圆满，毫无遗憾，我也不会认为我是一个没演完戏就退场的演员。对于我应有的责任，我丝毫不会逃避，我会尽全力的做好。
  <br></br><br></br>
 不论何时何地，我所掌握的就只有我能控制的，我虔诚接受我的命运，并会尽我最大的能力荣耀主，让自己的生命活出对人类最大的价值。
 <br></br><br></br>
阿们！
<br></br><br></br>
小宓
<br></br>
2022-12-25
</p>
      </div>
    </div>
  )
}
export default Testimonial1