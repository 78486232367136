import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import axios from "axios"

const EventList = () => {
  const [events, setEvents] = useState([])
  const [msg, setMsg] = useState("")

  useEffect(() => {
    getEvents()
  }, [])

  const getEvents = async () => {
    const response = await axios.get("https://main.mzac.org/events")
    setEvents(response.data)
  }

  const deleteEvent = async (eventId) => {
    try {
      await axios.delete(`https://main.mzac.org/events/${eventId}`)
      getEvents()
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg)
      }
    }
  }

  return (
    <div>
      <h1 className="title">Event</h1>
      <h2 className="subtitle">List of Event</h2>
      <Link to="/admin/events/add" className="button is-primary mb-2">
        Add New
      </Link>
      <p className="has-text-centered">{msg}</p>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>No</th>
            <th>Title</th>
            <th>Description</th>
            <th>Created Date</th>
            <th>File Name</th>
            <th>Created By</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {events.map((event, index) => (
            <tr key={event.uuid}>
              <td>{index + 1}</td>
              <td>{event.title}</td>
              <td>{event.desc}</td>
              <td>{event.date}</td>
              <td>{event.path}</td>
              <td>{event.user.name}</td>
              <td>
                <Link
                  to={`/admin/events/edit/${event.uuid}`}
                  className="button is-small is-info"
                >
                  Edit
                </Link>
                <button
                  onClick={() => deleteEvent(event.uuid)}
                  className="button is-small is-danger"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default EventList
