import React, {useState, useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';

import './style.css'
import {  Route, Routes } from 'react-router-dom'
import NavBar from './Components/NavBar/NavBar'
import Home from './Pages/Home'
import Footer from './Components/Footer'
import History from './Pages/About/History/History'
import Mission from './Pages/About/Mission/Mission';
import Pastoral from './Pages/About/Pastoral/Pastoral';

import Sermons from './Pages/Sermons/Sermons';

import Videos from './Pages/Videos/Videos';

import Testimonial from './Pages/Testimonials/Testimonials';
import TestimonialPage from './Pages/Testimonials/TestimonialPage';
import EventPage from './Pages/Home/EventPage';
import Young from './Pages/Ministries/Young/index';
import AttendeeForm from './Components/Register';
import Contact from './Pages/Contact';
import MicroChurches from './Pages/Ministries/Microchurches';
import Form from './Pages/Ministries/Microchurches/form';
import Giving from './Pages/Giving/Giving'
import Testimonial1 from './Pages/Testimonials/Testimonial1/Testimonial1'
import Testimonial2 from './Pages/Testimonials/Testimonial2/Testimonial2';
import Testimonial3 from './Pages/Testimonials/Testimonial3/Testimonial3';
import Weekly from './Pages/Weekly/Weekly'
import Weeklycurrent from './Pages/Weekly/Weeklycurrent/Weeklycurrent';
import Childrenministry from './Pages/Home/ChildrenMinistry/Childrenministry'
import SocialConcern from './Pages/Home/SocialConcern/Socialconcern'
import SocialConcern1 from './Pages/Home/SocialConcern/Socialconcern1/Socialconcern1'
import SocialConcern2 from './Pages/Home/SocialConcern/Socialconcern2/Socialconcern2'
import Alpha from './Pages/Home/Alpha/Alpha'
import Kids from './Pages/Home/ChildrenMinistry/Kids/Kids'
import Parents from './Pages/Home/ChildrenMinistry/Parents/Parents'

import Dashboard from "./Pages/Dashboard";
import Login from "./Components/Components/Login";
import Users from "./Pages/Users";
import Products from "./Pages/Products";
import Weeklys from "./Pages/Weeklys";
import Events from "./Pages/Events";
import Testimonials from "./Pages/Testimonials";
import Verses from "./Pages/Verses";
import Audios from "./Pages/Audios";
import AddUser from "./Pages/AddUser";
import EditUser from "./Pages/EditUser";
import AddProduct from "./Pages/AddProduct";
import EditProduct from "./Pages/EditProduct";
import AddWeekly from "./Pages/AddWeekly";
import EditWeekly from "./Pages/EditWeekly";
import AddAudio from "./Pages/AddAudio";
import EditAudio from "./Pages/EditAudio";
import AddEvent from "./Pages/AddEvent";
import EditEvent from "./Pages/EditEvent";
import AddTestimonial from "./Pages/AddTestimonial";
import EditTestimonial from "./Pages/EditTestimonial";
import AddVerse from "./Pages/AddVerse";
import EditVerse from "./Pages/EditVerse";



function App() {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 200) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Attach scroll event listener to the window
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
    <NavBar />
      <Routes>
        <Route path='/' element ={<Home />} />
        <Route path='/register' element ={<AttendeeForm />} />
        <Route path='/About/Mission' element={<Mission />} />
        <Route path='/Children' element={<Childrenministry />} />
        <Route path='/Socialconcern' element={<SocialConcern />} />
        <Route path='/socialconcern1' element={<SocialConcern1 />} />
        <Route path='/socialconcern2' element={<SocialConcern2 />} />
        <Route path='/Children/kids' element={<Kids />} />
        <Route path='/Children/parents' element={<Parents />} />
        <Route path='/Alpha' element={<Alpha />} />
        <Route path='/ministries' element={<Weekly />} />
        <Route path='/About/Pastoral' element={<Pastoral />} />
        <Route path='/About/History' element={<History />} />
        <Route path='/Sermons/' element={<Sermons />} />
        <Route path='/Videos/' element={<Videos />} />

        <Route path='/Weekly/' element={<Weeklycurrent />} />
        <Route path='/Testimonials' element={<Testimonial />} />
        <Route path='/Testimonial/:id' element={<TestimonialPage />} />
        <Route path='/event/:id' element={<EventPage />} />
        <Route path='/Testimonial1' element={<Testimonial1 />} />
        <Route path='/Testimonial2' element={<Testimonial2 />} />
        <Route path='/Testimonial3' element={<Testimonial3 />} />
        <Route path='/ministries/young-adults' element={<Young />} />
        <Route path='/ministries/micro-churches' element={<MicroChurches />} />
        <Route path='/ministries/micro-churches/form' element={<Form/>} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/give' element={<Giving />} />
       
        <Route path="/admin" element={<Login />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/users" element={<Users />} />
          <Route path="/admin/users/add" element={<AddUser />} />
          <Route path="/admin/users/edit/:id" element={<EditUser />} />
          <Route path="/admin/products" element={<Products />} />
          <Route path="/admin/products/add" element={<AddProduct />} />
          <Route path="/admin/products/edit/:id" element={<EditProduct />} />
          <Route path="/admin/weeklys" element={<Weeklys />} />
          <Route path="/admin/weeklys/add" element={<AddWeekly />} />
          <Route path="/admin/weeklys/edit/:id" element={<EditWeekly />} />
          <Route path="/admin/audios" element={<Audios />} />
          <Route path="/admin/audios/add" element={<AddAudio />} />
          <Route path="/admin/audios/edit/:id" element={<EditAudio />} />
          <Route path="/admin/events" element={<Events />} />
          <Route path="/admin/events/add" element={<AddEvent />} />
          <Route path="/admin/events/edit/:id" element={<EditEvent />} />
          <Route path="/admin/testimonials" element={<Testimonials />} />
          <Route path="/admin/testimonials/add" element={<AddTestimonial />} />
          <Route path="/admin/testimonials/edit/:id" element={<EditTestimonial />} />
          <Route path="/admin/verses" element={<Verses />} />
          <Route path="/admin/verses/add" element={<AddVerse />} />
          <Route path="/admin/verses/edit/:id" element={<EditVerse />} />
     
      </Routes>
      
      <Footer />
    </>
  )
}

export default App