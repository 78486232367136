import React, { useRef, useState, useEffect } from "react";
import {
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
  FaLocationArrow,
  FaTimes,
} from "react-icons/fa";
import "./style.css"; // Import your CSS file for styling
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Input,
  SkeletonText,
  Text,
} from "@chakra-ui/react";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api";
//import { ChakraProvider, theme } from "@chakra-ui/react";
import emailjs from "emailjs-com";

import { useTranslation } from "react-i18next";

import enTranslation from "../../locales/en.json";
import chTranslation from "../../locales/ch.json";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import churchLocation from "./churchLocation.png"
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ch: { translation: chTranslation },
  },
  lng: "ch",
  fallbacklng: "ch",
  interpolation: {
    escapeValue: false,
  },
});

const center = { lat: 45.49521, lng: -73.61334 };
function SearchLocation() {
  const { t } = useTranslation();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,

    libraries: ["places"],
    id: "google-map-script",
  });

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");

  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef();
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destiantionRef = useRef();

  const defaultDestination = "4824 Chem. de la Côte-des-Neiges, Montreal";

  if (!isLoaded) {
    return <SkeletonText />;
  }

  async function calculateRoute() {
    if (originRef.current.value === "" || destiantionRef.current.value === "") {
      return;
    }

    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: originRef.current.value,
      destination: destiantionRef.current.value,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);
  }

  function clearRoute() {
    setDirectionsResponse(null);
    setDistance("");
    setDuration("");
    originRef.current.value = "";
    destiantionRef.current.value = "";
  }

  return (
    <Flex
      position="relative"
      flexDirection="column"
      alignItems="center"
      h="100vh"
      w="100vw"
    >
      <Box position="absolute" left={0} top={0} h="100%" w="100%">
        {/* Google Map Box */}
        <GoogleMap
          center={center}
          zoom={15}
          mapContainerStyle={{ width: "100%", height: "100%" }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          onLoad={(map) => setMap(map)}
        >
          <Marker position={center} />
          {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} />
          )}
        </GoogleMap>
      </Box>
      <Box
        p={4}
        borderRadius="lg"
        m={4}
        bgColor="white"
        shadow="base"
        minW="container.md"
        zIndex="1"
      >
        <HStack spacing={2} justifyContent="space-between">
          <Box flexGrow={1}>
            <Autocomplete>
              <Input
                type="text"
                placeholder={t("contact.origin")}
                ref={originRef}
              />
            </Autocomplete>
          </Box>
          <Box flexGrow={1}>
            <Autocomplete>
              <Input
                type="text"
                placeholder="Destination"
                ref={destiantionRef}
                defaultValue={defaultDestination}
              />
            </Autocomplete>
          </Box>

          <ButtonGroup>
            <Button colorScheme="blue" type="submit" onClick={calculateRoute}>
              {t("contact.cal")}
            </Button>
            <IconButton
              aria-label="center back"
              icon={<FaTimes />}
              onClick={clearRoute}
            />
          </ButtonGroup>
        </HStack>
        <HStack spacing={4} mt={4} justifyContent="space-between">
          <Text color={"black"}>
            {t("contact.distance")} {distance}{" "}
          </Text>
          <Text color={"black"}>
            {t("contact.duration")} {duration}{" "}
          </Text>
          <IconButton
            aria-label="center back"
            icon={<FaLocationArrow />}
            isRound
            onClick={() => {
              map.panTo(center);
              map.setZoom(15);
            }}
          />
        </HStack>
      </Box>
    </Flex>
  );
}

function ContactDetails() {
  const { t } = useTranslation();

  const form = useRef();
  const [isSent, setIsSent] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_mnmujf9",
        "template_mjoirkx",
        form.current,
        "IASEw7Wc03lq7D_9L"
        // "service_w2s528b",
        // "template_mjt2o6i",
        // form.current,
        // "06EJpfDoCwxkxDYzK"
      )
      .then((response) => {
        console.log("Email sent!", response.status, response.text);
        setIsSent(true);
        setTimeout(() => {
          setIsSent(false);
        }, 3000); // Change the time (in milliseconds) how long you want to display the success message
      })
      .catch((error) => {
        console.error("Email failed to send:", error);
        setIsSent(false);
      });
    e.target.reset();
  };
  return (
    // <section className="info">
    //      <h3 className="title">Contact Us</h3>
    //     <div className="cardContainer">
    //       <div className="card">
    //         <FaPhone className="icon" />
    //         <p>Office Phone</p>
    //         <p>+254755637745</p>
    //       </div>
    //       <div className="card">
    //         <FaEnvelope className="icon" />
    //         <p>Email</p>
    //         <p>info@dcutawala.org</p>
    //         <p>utawaladc@gmail.com</p>
    //       </div>
    //       <div className="card">
    //         <FaMapMarkerAlt className="icon" />
    //         <p>Location</p>
    //         <p>300m from ACK St. Monica, Utawala, Nairobi</p>
    //       </div>
    //     </div>

    // </section>
    <section id="contact">
      <h3 className="title">{t("contact.us")} </h3>

      <div className="container contact_container">
        {isSent && (
          <p className="success-message">Message Sent Successfully!</p>
        )}
        <div className="contact_options">
          <article className="card">
            <div className="contact_option-icon" />
            <FaPhone className="icon" />
            <p>{t("contact.phone")} </p>
            <p>{t("contact.number")} </p>
          </article>
          <article className="card">
            <div className="contact_option-icon" />
            <FaEnvelope className="icon" />
            <p>{t("contact.emaile")}</p>
            <p>{t("contact.emailc")}</p>
          </article>
          <article className="card">
            <div className="contact_option-icon" />
            <FaMapMarkerAlt className="icon" />
            <p>{t("contact.location")}</p>
            <p>{t("contact.address")}</p>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input
            className="card"
            type="text"
            name="name"
            placeholder={t("contact.name")}
            required
          />
          <input
            className="card"
            type="email"
            name="email"
            placeholder={t("contact.email")}
            required
          />
          <textarea
            className="card"
            name="message"
            rows="15"
            placeholder={t("contact.message")}
            required
          ></textarea>
          <button type="submit" className="btn btn-translation">
            {t("contact.send")}{" "}
          </button>
        </form>
        <div>{/* Your other content */}</div>
      </div>
    </section>
  );
}

function Contact() {
  return (
    <div>
      {/* <ChakraProvider theme={theme}>
        <SearchLocation />
      </ChakraProvider> */}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={churchLocation} alt="churchLocation" style={{ maxWidth: '100%', maxHeight: '100%', margin: 'auto' }}/>
      </div>
      <ContactDetails />
    </div>
  );
}

export default Contact;
