import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import { useParams } from "react-router-dom"
import "./testimonial1.css"

import { useTranslation } from "react-i18next"

import enTranslation from "../../locales/en.json"
import chTranslation from "../../locales/ch.json"

import i18n from "i18next"
import { initReactI18next } from "react-i18next"

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ch: { translation: chTranslation },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
})

function TestimonialPage () {
  const { t } = useTranslation()

  const { id } = useParams() // Get the testimonial ID from the URL
  const [testimonial, setTestimonial] = useState(null)

  useEffect(() => {
    // Fetch testimonial data from the backend based on the ID
    axios
      .get(`https://main.mzac.org/testimonials/${id}`)
      .then((response) => {
        setTestimonial(response.data)
      })
      .catch((error) => {
        console.error("Error fetching testimonial:", error)
      })
  }, [id]) // Include id in the dependency array to re-fetch data when id changes

  if (!testimonial) {
    return <div>Loading testimonial...</div>
  }

  const { title, desc, path,  } = testimonial

  return (
    <div className="testimonial1-container">
      {/* <p className="testimonial-title">{t("testimonial.testimonial")} </p> */}
      <h3>{t("testimonial.ourStories")} </h3>
      <div className="testimonial-context">
        <h4>{t("testimonial.description")}</h4>
      </div>
      <div className="testimonial1-image">
        <img
          src={`https://main.mzac.org/testimonialFile/${path}`}
          alt={`Testimonial1 Small - ${title}`}
          style={{ width: "600px", height: "400px" }}
        />
        <img
          src={`https://main.mzac.org/testimonialFile/${path}`}
          alt={`Testimonial1 Small - ${title}`}
          style={{ width: "600px", height: "400px" }}
        />
        <img
          src={`https://main.mzac.org/testimonialFile/${path}`}
          alt={`Testimonial1 Small - ${title}`}
          style={{ width: "600px", height: "400px" }}
        />
      </div>

      <div className="testimonials-context">
        {desc.split(" ").map((item, index) => (
          <p key={index}>{item.trim()}</p>
        ))}
      </div>
      <Link to="/Testimonials">
        <button className="btn btn-back">{t("testimonial.back")}</button>
      </Link>
    </div>
  )
}

export default TestimonialPage
