import React from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

class Weeklycurrent extends React.Component {
  state = {
    numPages: null,
    pageNumber: 1,
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  render() {
    const { pageNumber, numPages } = this.state;

    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div style={{ width: '80%', height: '80vh' }}>
          <Document
            file="your_pdf_document.pdf"
            onLoadSuccess={this.onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} width={window.innerWidth * 0.8} />
          </Document>
          <p style={{ textAlign: 'center' }}>
            Page {pageNumber} of {numPages}
          </p>
        </div>
      </div>
    );
  }
}

export default Weeklycurrent;
